@themeColor: #ff822c;
@hoverColor: #e0640f;
@black: #000000;

.swiper {
  width: 100%;
  padding: 0 0 50px 0;
  z-index: 0;

  :global {
    .swiper-pagination-bullet-active {
      background-color: @themeColor;
    }
  }
}

.swiperSlide {
  width: 100%;
  align-self: stretch;
  display: flex;
  justify-content: space-around;
  transition: all 0.3s;
  height: inherit;
}

.moveListItemBox {
  width: 100%;
  height: inherit;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}

.listNoWrap {
  flex-wrap: nowrap;
}

.needFillSpace {
  width: auto;
  height: 1px;
  opacity: 0;
}

.preBtn {
  position: absolute;
  left: 1px;
  top: 48%;
  z-index: 999;
  transform: translate(0, -100%);
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 2px 6px 0 rgb(18 15 28 / 20%);
  cursor: pointer;
  outline: 0;
  //overflow: hidden;
  border-radius: 100%;
  transition: all 0.3s;
  &:hover {
    border-color: #ff822c;
    svg path {
      fill: #ff822c;
    }
  }
}

.nextBtn {
  position: absolute;
  right: 1px;
  top: 48%;
  transform: translate(0, -100%);
  z-index: 999;
}

.none {
  display: none;
}

.leftIcon {
  transform: rotate(180deg);
}